@import "~@/erp/styles/variables/variables.scss";































































































.base-info-wrapper {
  em {
    display: inline-block;
    width: 85px;
  }

  span {
    display: inline-block;
  }
}
